import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Family from "./icons/Family";
import Wallet from "./icons/Wallet";
import Education from "./icons/Education";
import Shopping from "./icons/Shopping";
import Settings from "./icons/Settings";
import EditProfileIcn from "./icons/EditProfileIcn";
import Logout from "./icons/Logout";
import Timeline from "./icons/Timeline";
import { SESSSION_OUT } from "../../../../Utils/constants";

const Sidebar = ( props ) => {

  const { t } = useTranslation();
  const location = useLocation();
  const isBank = !props.showHeader || sessionStorage.getItem("__bank");

  const logout = () => {
    SESSSION_OUT(props);
  };

  const toggleSidebar = () => {
    document.querySelector('.parent-left-sidebar').classList.toggle('active');
  }

  return (
    <div className="parent-left-sidebar">
      <div className="overlayDiv" onClick={toggleSidebar}></div>
      {/* <div className="expendedIcon" onClick={toggleSidebar}>
        <img src={`${BASE_URL.CDN}/tenants/${props.state.theme.values.shortName}/web/public/assets/images/menuBar.svg`} alt="menubar" />
      </div> */}
      <ul>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/dashboard" ? 'active' : ''}`}>
          <Link to="/parent/dashboard">
            <span className="menu-icon">
              <span className="svgIcon">
                <Family {...props}/>
              </span>
            </span>
            <span>{t('my_family')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/timeline" ? 'active' : ''}`}>
          <Link to="/parent/timeline">
            <span className="menu-icon">
              <span className="svgIcon">
                <Timeline {...props}/>
              </span>
            </span>
            <span>{t('timeline')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/education" ? 'active' : ''}`}>
          <Link to="/parent/education">
            <span className="menu-icon">
              <span className="svgIcon">
                <Education {...props}/>
              </span>
            </span>
            <span>{t('education')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/wallet" ? 'active' : ''}`}>
          <Link to="/parent/wallet">
            <span className="menu-icon">
              <span className="svgIcon">
                <Wallet {...props}/>
              </span>
            </span>
            <span>{t('wallet')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/shopping" ? 'active' : ''}`}>
          <Link to="/parent/shopping">
            <span className="menu-icon">
              <span className="svgIcon">
                <Shopping {...props}/>
              </span>
            </span>
            <span>{t('shopping')}</span>
          </Link>
        </li>

        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/profile" ? 'active' : ''}`}>
          <Link to="/parent/profile">
            <span className="menu-icon">
              <span className="svgIcon">
                <EditProfileIcn {...props}/>
              </span>
            </span>
            <span>{t('profile')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/setting/dashboard" ? 'active' : ''}`}>
          <Link to="/parent/setting/dashboard">
            <span className="menu-icon">
              <span className="svgIcon">
                <Settings {...props}/>
              </span>
            </span>
            <span>{t('settings')}</span>
          </Link>
        </li>
        {/* <li className={`${location.pathname==="/help"?'active':''}`}>
          <Link to="/parent/help">
            <span className="menu-icon">
              <span className="svgIcon">
                <Help/>
              </span>
            </span>
            <span>{t('help')}</span>
          </Link>
        </li> */}

        {!isBank && 
          <li onClick={toggleSidebar} className={`${location.pathname === "/parent/logout" ? 'active' : ''}`}>
            <a href="#logout" onClick={logout}>
              <span className="menu-icon">
                <span className="svgIcon">
                  <Logout {...props}/>
                </span>
              </span>
              <span>{t('sign_out')}</span>
            </a>
          </li>
          }
      </ul>
    </div>
  );
};

export default Sidebar;
