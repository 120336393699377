import React from "react";

const EditProfileIcn = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

  return (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      id="editProfile"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dribbble-Light-Preview"
          transform="translate(-420.000000, -2159.000000)"
          fill={iconColor}
        >
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path
              d="M374,2009 C371.794,2009 370,2007.206 370,2005 C370,2002.794 371.794,2001 374,2001 C376.206,2001 378,2002.794 378,2005 C378,2007.206 376.206,2009 374,2009 M377.758,2009.673 C379.124,2008.574 380,2006.89 380,2005 C380,2001.686 377.314,1999 374,1999 C370.686,1999 368,2001.686 368,2005 C368,2006.89 368.876,2008.574 370.242,2009.673 C366.583,2011.048 364,2014.445 364,2019 L366,2019 C366,2014 369.589,2011 374,2011 C378.411,2011 382,2014 382,2019 L384,2019 C384,2014.445 381.417,2011.048 377.758,2009.673"
              id="profile-[#1335]"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EditProfileIcn;
